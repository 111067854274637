import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/theme/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Ce billet est la transposition statique du classeur: `}<a parentName="em" {...{
          "href": "https://observablehq.com/@alainro/observable-en-observable"
        }}>{`Observable en Observable`}</a></em>{` `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "568px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.44171779141104%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsSAAALEgHS3X78AAACO0lEQVQozyWS/U/acBjE+9/PBF+GmQsg6gKMRV6mzm0iKrEYMCtTUF5aQBlvfgsILVIKbWmfW7Pd73e5z+W4Hx2osxlqMTRO4WgDXWGKBisF/B6ZOGP0soDjLG3zElYbakt7fS4M0X8zMS5ziuFMTNs2CrDbjkOnf5xvXVjWi1jhL9IX5xn+qdOvzqCOO6gcoHdVGtN+jVJ90KjEwerBapKxAytpaYOqQtXBLB4/CgYPbm5uMleXft9HPi8Qy+PXGko7E3WUYWhOgX6WI61Nah22YJlSR0NVRer7idf7njGGf3p4KK2vb5TvcpBzNKkDBZpXIMXQ+smBGKxnZ9Vx4S8ZmMy829uJRMK1NRoNWZZ1Xff7fNHDhLbQ0eMxucfrIwnrKH7gaFV10+dm89Yto0ESa1tbW+l0ej6fezyeUChkGEY4HNr7FJ5JZyisUWkPeh8sq8kiR1YXw9x48vZmdoGTdqvg9e4cHx8RkSAI9Xp9sVjs7gZCkc/muAkxSt0c7Dw5QvoFHFZDNOLGUFqu2jAi1lKMhKMBv286nf5nfn5qbm5sZrM8IJBWRSvtjG4BRZqCc6wz0148jCEpJiC7pP1uzR8IfolGXIRS8T6wG/yaTBp6BZBoVMTtOzSSmD5hWOSgs1QH4sS9gkSWTK8FNGKTTvkicx2NRg9jcSHHm8YQ1j6ZKVqpGGTZUFaWKwzyHJvbkoqpabubExPQ5UnwYHQHxqN3jcUAUhjtFNki7BqZJ3DOq6pdViAqq7+pqTh7RtxLhQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "observablehq",
          "title": "observablehq",
          "src": "/static/bb937cf5001ec75c2b19431f6de6ac04/10e91/observablehq.png",
          "srcSet": ["/static/bb937cf5001ec75c2b19431f6de6ac04/222b7/observablehq.png 163w", "/static/bb937cf5001ec75c2b19431f6de6ac04/ff46a/observablehq.png 325w", "/static/bb937cf5001ec75c2b19431f6de6ac04/10e91/observablehq.png 568w"],
          "sizes": "(max-width: 568px) 100vw, 568px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <p><a parentName="p" {...{
        "href": "https://observablehq.com"
      }}>{`Observablehq`}</a>{` est un environnement de développement collaboratif `}<strong parentName="p">{`tout en un`}</strong>{` dans le navigateur. Le monde de la dataviz s’est approprié cette plateforme très innovante en quelques mois. Rien d'étonnant Mike Bostock (le créateur de d3.js) en est à l'origine.   `}</p>
    <br />
    <p>{`Une fois franchie la barrière d'étrangeté, vous ne pourrez plus vous en passer…  `}</p>
    <br />
    <p>{`J'utilise Observable depuis la première beta test de d3.express en 2017. Le `}<a parentName="p" {...{
        "href": "https://medium.com/@mbostock/a-better-way-to-code-2b1d2876a3a0"
      }}>{`papier initial`}</a>{` de Mike (Avril 2017) me semble toujours d'actualité avec ses 4 chapitres: `}</p>
    <ol>
      <li parentName="ol">{`Réactivité`}</li>
      <li parentName="ol">{`Visibilité`}</li>
      <li parentName="ol">{`Réutilisabilité`}</li>
      <li parentName="ol">{`Portabilité`}</li>
    </ol>
    <p>{`Lecture très technique et en anglais mais hautement recommandée.`}</p>
    <h1>{`Qu'est ce exactement ?`}</h1>
    <h2>{`En un mot: Magique`}</h2>
    <p>{`Le qualificatif peut sembler excessif au chaland. Il n'en est rien. Observable rassemble une combinaison de capacités qui forment un ensemble absolument jamais vu auparavant.`}</p>
    <ul>
      <li parentName="ul">{`Les tremblements de terre sur un globe en 10 minutes: `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=uEmDwflQ3xE"
        }}>{`ici`}</a>{` et `}<a parentName="li" {...{
          "href": "https://observablehq.com/@jashkenas/earthquakes"
        }}>{`là`}</a></li>
      <li parentName="ul">{`L'intro de 5 minutes: `}<a parentName="li" {...{
          "href": "https://observablehq.com/@observablehq/five-minute-introduction"
        }}>{`ici`}</a>{`  `}</li>
      <li parentName="ul">{`Les projections géographiques de d3js: `}<a parentName="li" {...{
          "href": "https://observablehq.com/@fil/tissots-indicatrix"
        }}>{`ici`}</a>{` et `}<a parentName="li" {...{
          "href": "https://observablehq.com/@fil/d3-projections"
        }}>{`là`}</a></li>
      <li parentName="ul">{`Les délires WebGL: `}<a parentName="li" {...{
          "href": "https://observablehq.com/@jashkenas/webgl-fluid-simulation"
        }}>{`ici`}</a></li>
    </ul>
    <h2>{`En deux mots: Tableur javascript`}</h2>
    <p>{`Un notebook est un ensemble de cellules nommées ou anonymes qui se comportent comme un `}<strong parentName="p">{`tableur`}</strong>{`. Chaque cellule calcule une expression à partir des valeurs d'autres cellules. La modification d'une valeur ou d'une expression dans ces chaînes de dépendance provoque le recalcul automatique immédiat de tout ce qui a changé. `}</p>
    <br />
    <blockquote>
      <p parentName="blockquote">{`Imaginer un tableur où les cellules sont définies comme des expressions (ou programmes) javascript. `}<strong parentName="p">{`Voilà Observable`}</strong>{`  `}</p>
    </blockquote>
    <br />
    <p>{`Chaque cellule a: `}</p>
    <ul>
      <li parentName="ul">{`un `}<strong parentName="li">{`nom`}</strong>{` (optionnel)`}</li>
      <li parentName="ul">{`une `}<strong parentName="li">{`définition`}</strong>{` (une expression ou programme javascript)`}</li>
      <li parentName="ul">{`une `}<strong parentName="li">{`valeur`}</strong>{` qui peut être explorée à la souris y compris les structures les plus complexes `}</li>
      <li parentName="ul">{`une `}<strong parentName="li">{`punaise`}</strong>{` qui permet de `}<strong parentName="li">{`rendre la définition visible constamment`}</strong>{` autrement l'ouverture de la définition d'une cellule ferme toutes celles déjà ouvertes`}</li>
    </ul>
    <br />
    <p>{`Il existe des valeurs prédéfinies qui facilitent la réalisation de notebook responsive.   `}</p>
    <p>{`Le meilleur exemple: `}<strong parentName="p">{`width`}</strong>{` Il suffit d'utiliser cette variable pour obtenir comme par magie une application responsive qui va automatiquement s'adapter à la taille de la fenêtre. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "64.41717791411043%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAABg0lEQVQ4y4WTS4/CMAyE8///Fgfu5cKBtrSlD6C8qj6gFLz7eWWUrVZaS6FJPBmPJ8TJd7zf71+DmKZJzuez7Pd7ud1u8nq9ZI618Pecn7CkfS+Xi2RZJm3byhznE/nh+KE6wwc8Hg+p61q/VVV9DvjY+R5DFdJSFEVyOp0UcL/fZbvdSlEUSpjnuWKGYZCyLDWHeiMlF8exHA6HH4VsNE0j1+tVxnFUQsiZA0qSRA9AjK+QYQMFUMWa4qzd8/mUvu8/8gHiW5qmEoahFgNM+8fjUXa7neboiLURgVMPu67TG7VgDiEKIaQtCtI6xdmHEAwdQUje1CqhH6aYfQaKbWCLYbACP813sB9CCMwzwhThJZXxbrPZyHq9VlLaYx4EgSqkCxPmAFCNFjCc6lQy07GAOX9wfMRDWuUcHrJHAbNNL4Ukh1BkraIQ1RBwi+xBwBwcXZha60xfik0gRpH5B9lisZDVaiXL5VJvFQKzyC5p/sLcX8+HNSrsv4kVFPvv+fH9AmZO7Asae2V7AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Dependances",
          "title": "Dependances",
          "src": "/static/2ae179c949477a80e0f04dfbdf519c76/a6d36/Dependances.png",
          "srcSet": ["/static/2ae179c949477a80e0f04dfbdf519c76/222b7/Dependances.png 163w", "/static/2ae179c949477a80e0f04dfbdf519c76/ff46a/Dependances.png 325w", "/static/2ae179c949477a80e0f04dfbdf519c76/a6d36/Dependances.png 650w", "/static/2ae179c949477a80e0f04dfbdf519c76/1b1d5/Dependances.png 876w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Observable entretient le graphe des dépendances inter cellules. L'ordre des cellules dans le notebook n'a aucune influence sur les calculs effectués.`}</p>
    <p>{`Tout est expliqué dans ce `}<a parentName="p" {...{
        "href": "https://observablehq.com/@observablehq/how-observable-runs"
      }}>{`notebook`}</a></p>
    <br />
    <blockquote>
      <p parentName="blockquote">{`Cette capacité d'Observable a être réactif est à mon avis l'innovation la plus profonde et la plus fructueuse.`}</p>
    </blockquote>
    <h2>{`En trois mots: Développement collaboratif javascript`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.44171779141104%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAABc0lEQVQoz31Sa5OCMAzs//9lfpUvOo4DKqK8obxEqa7d3JXzbvQyk2lIw2azqTqdTthut/B9H2EY4n6/4/F4gMbzndP6vsflcpGzbVuE+z2iwIfq2gZFnqFpGkzTBGMMyrLEMAwfQWmsvd1u4oyv1yvMZKCqokRVaWFG44XneYii6F9A1rPWAZMI79QwGuh2tAVm7pxlmW1S4ZM50Hd51dqR0ySeR2RnatJ1nehDH75P5qgbGY3jODtzLq+oHccjiAMkuziOkaYp8jwXxtSV+aIoJM9Yay3au2YEVwSq6/oXbRYvlx6Ox0g2v9ls5CUQmCCLxQLr9RpBEOB8Pks9Maip4qj8cEtxI2dZPrPgvfuJ34llSKbMsdY9HWHIJTD4ATS2YEDbNbZICwOypARfLKxuOkdvR1ytVtjtdnLP0QXw76YITMCmLm2RFiZJkoiTEcXv6kJYHQ4HK8vxpZkd+d37et3wqzMvI1qZGJMVJWDslvIE8/FQrCTCHBMAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Ecran",
          "title": "Ecran",
          "src": "/static/30717ff394a5c2417af7b40533073596/a6d36/Ecran.png",
          "srcSet": ["/static/30717ff394a5c2417af7b40533073596/222b7/Ecran.png 163w", "/static/30717ff394a5c2417af7b40533073596/ff46a/Ecran.png 325w", "/static/30717ff394a5c2417af7b40533073596/a6d36/Ecran.png 650w", "/static/30717ff394a5c2417af7b40533073596/e548f/Ecran.png 975w", "/static/30717ff394a5c2417af7b40533073596/c56af/Ecran.png 1231w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h3>{`Un espace personnel`}</h3>
    <p>{`Tous les utilisateurs ont un espace personnel, voici par exemple mes notebooks publics: `}<a parentName="p" {...{
        "href": "https://observablehq.com/@alainro"
      }}>{`https://observablehq.com/@alainro`}</a></p>
    <br />
    <p>{`Tous les Notebooks, les Collections, les Suggestions sont dans le panneau de gauche.
Les Likes, l'exploration et le Help sont dans le menu compte (la photo).`}</p>
    <ul>
      <li parentName="ul">{`Le `}<a parentName="li" {...{
          "href": "https://observablehq.com/@observablehq/user-manual"
        }}>{`manuel`}</a></li>
      <li parentName="ul">{`Le `}<a parentName="li" {...{
          "href": "https://talk.observablehq.com/"
        }}>{`forum`}</a></li>
    </ul>
    <h3>{`Editeur de texte et sandbox d'exécution`}</h3>
    <p>{`Il existe plusieurs sites/services permettant de développer du javascript (html et css) dans le navigateur:`}</p>
    <ul>
      <li parentName="ul">{`des généralistes : `}<a parentName="li" {...{
          "href": "https://codepen.io/"
        }}>{`codepen`}</a>{`,   `}<a parentName="li" {...{
          "href": "https://jsfiddle.net/"
        }}>{`jsFiddle`}</a>{`, `}<a parentName="li" {...{
          "href": "https://plnkr.co/"
        }}>{`Plunker`}</a>{` et`}</li>
      <li parentName="ul">{`des spécialisés en `}<a parentName="li" {...{
          "href": "https://d3js.org/"
        }}>{`d3js`}</a>{`: `}<a parentName="li" {...{
          "href": "https://bl.ocks.org/"
        }}>{`bl.ocks.org`}</a>{`, `}<a parentName="li" {...{
          "href": "http://blockbuilder.org"
        }}>{`blockbuilder.org`}</a>{` ou `}<a parentName="li" {...{
          "href": "https://vizhub.com/"
        }}>{`vizhub.com`}</a></li>
    </ul>
    <br />
    <p>{`L'éditeur de texte est assez rudimentaire mais plutôt confortable. Il propose la complétion automatique, le pretty print et la gestion des () {} []`}</p>
    <h3>{`Gestionnaire de versions`}</h3>
    <p>{`Des versions sont automatiquement crées et il est possible de se `}<strong parentName="p">{`promener dans l'arbre`}</strong>{` des versions, de revenir à toute version antérieure et même de comparer visuellement la version courante à n'importe quelle autre.`}</p>
    <p>{`On accède à la gestion de version par le menu ... et l'icône `}<strong parentName="p">{`View history`}</strong>{` qui fait apparaitre la liste des modifications groupées par versions et deux boutons <- et ->.`}</p>
    <h3>{`Espace de publication et de partage`}</h3>
    <p>{`Un notebook peuvent être `}<strong parentName="p">{`privé`}</strong>{` (par défaut) ou `}<strong parentName="p">{`public`}</strong>{` (après publication). Il existe un intermédiaire entre privé et public: `}<strong parentName="p">{`le partage de lien`}</strong>{` qui autorise le possesseur du lien à lire et si besoin à `}<strong parentName="p">{`forker`}</strong>{` le notebook pour le modifier. `}</p>
    <br />
    <p>{`Tous les notebooks publics sont accessibles sur la page d'accueil d'Observable et peuvent être cherchés par mots clés, auteur, popularité. `}</p>
    <ul>
      <li parentName="ul">{`publiés récemment: `}<a parentName="li" {...{
          "href": "https://observablehq.com/recent"
        }}>{`https://observablehq.com/recent`}</a></li>
      <li parentName="ul">{`organisés par collections: `}<a parentName="li" {...{
          "href": "https://observablehq.com/@observablehq?tab=collections"
        }}>{`https://observablehq.com/@observablehq?tab=collections`}</a></li>
    </ul>
    <br />
    <p>{`Le partage est effectué par:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`fork`}</strong>{` (copie locale de la version courante) qui permet de s'approprier le notebook pour le modifier et si besoin de l'envoyer à l'auteur de la version originale pour suggestions de modifications prêtes à être fusionnées`}</li>
      <li parentName="ul"><strong parentName="li">{`importation`}</strong>{` (utilisation d'une ou plusieurs cellules de notebook comme des packages offrants des fonctions javascript)`}</li>
    </ul>
    <h3>{`Espace collaboratif (Teams)`}</h3>
    <p>{`Chaque cellule peut être commentée. La version payante ($9 par mois par auteur), permet à plusieurs auteurs de travailler à plusieurs mains sur une même version de notebook.`}</p>
    <h3>{`Espace de stockage de données`}</h3>
    <p>{`On peut aussi attacher des fichiers jusqu'à 15Mo au notebook. Les fichiers restent locaux au notebook et partage son statut privé/public. Ils sont en lecture seulement. Ils peuvent être des images, du json ou csv, shapefile, stream, ...
`}<a parentName="p" {...{
        "href": "https://observablehq.com/@observablehq/file-attachments"
      }}>{`Les détails techniques sont ici`}</a></p>
    <h1>{`Pourquoi faire ?`}</h1>
    <p>{`Observable est un environnement douillet qui permet d'oublier beaucoup de difficultés techniques inhérentes à javascript: la gestion de version, le gestionnaire de package, la collaboration, la publication, ...   `}</p>
    <br />
    <p>{`Il est précieux au débutant qui ne veut pas s'embarrasser avec les méandres techniques tout aussi bien qu'à l'expert souhaitant faire une maquette d'un algorithme ou d'une visualisation et la partager avec un client et des collaborateurs. Facile pour le débutant (javascript, d3, vega, carto, ...) et très confortable pour le spécialiste qui peut se concentrer sur l'essentiel (l'algorithme, la visualisation, le dialogue avec son client, ...).`}</p>
    <h2>{`Exemples d'usages`}</h2>
    <br />
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Avantages`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`article scientifique`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`collaboratif, versions, Latex`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`billet de blog 'qui bouge'`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`réactivité, widgets de saisie`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`maquettage UX`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`collaboratif, responsive gratuit`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`itérations avec un client`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`suivi des remarques, versions`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`développement de composants`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`importation de cellules dans des applications web`}</td>
        </tr>
      </tbody>
    </table>
    <br />
    <h2>{`La gigantesque bibliothèque de notebook qui font 'presque' ce dont on a besoin`}</h2>
    <p>{`Cherchez et trouvez le notebook presque parfait parmi ceux qui existent déjà, l'adapter et le tour est joué. `}</p>
    <blockquote>
      <p parentName="blockquote">{`Plus de 10k notebooks disponibles`}</p>
    </blockquote>
    <p>{`Bien sûr, il est aussi nécessaire d'apprendre comment ça marche en utilisant les tutos disponibles par exemple: `}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://observablehq.com/@observablehq/tutorial-3-visualizing-data"
        }}>{`Observable`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://observablehq.com/@fil/d3-documentation-on-observable"
        }}>{`d3js`}</a></li>
    </ul>
    <h2>{`Les o-matic`}</h2>
    <p>{`Des notebook qui n'attendent que les données pour tracer des visualisations sophistiquées: treemap, circle packing, ... On copie/colle ses données identées et on télécharge l'image SVG ou PNG. Aucune connaissance javascript n'est nécessaire.`}</p>
    <br />
    <p>{`Il suffit de chercher o-matic dans la barre de recherche. `}<a parentName="p" {...{
        "href": "https://observablehq.com/search?query=o-matic"
      }}>{`https://observablehq.com/search?query=o-matic`}</a></p>
    <p>{`Par exemple:`}</p>
    <h3>{`Bars-o-matic`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "46.62576687116564%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsSAAALEgHS3X78AAAB8klEQVQoz4WS3WvTUBiHA7nwnxRvpHjhZfVCFAZDxE3FCU50cx+idpudbgzUtWVtsBWmk82ly9KlSdq16bdN29g2Jz1Jfp4qxcKGPvDj8HIOz3nPB6eqOp9OS7yiqHyxaPCFgsHX6uYFjmEP/Mtg2Lbtdrs9+L4/LM+Mnu/BJjY81wP3L9jaS/gDHQlGGRc6DoGmZkEIAaeqWlAU08FMRglqWo5FD2YV9ZosZa6C2pPlRg+RLyUPYwxFnuePKvj+3zmu0WiiVKqg2WzhHFbyiu5GQu+hF1sgjvv7WCOG0mHGO+fy+QJlXdFarUH7fUKJQ2mvUqed/SO3/f0kSjJ7fnfuBhbeiQhHZGwIOWwKOo6z9fMaAJfL5aksn9DT0yK1rC4l1KWWVqDNbcFtJr7Gibzn/3x2Ey83JTxek7CxnsLiqyQWP+gQNRPf5B9oWxQWAfrsprlqtY5yucpe0jmzG7uaFUsT3erSLcjhecTDb9AOTaOzfBvJ1S1shQTo8/ews53Ax7cxaAfH4AyjHGDSgGm2A4QMAkwcsHv2lY7ZusicE0qrguvJkHd3N4yJz68xk1rDo8QynseXsBp7gdn4AqZ21zEZm4Mgp/7/baqDLqb3o/RJ+hMeHsYxK6VwXxTwgGUmLWDqMIGnR0ncOYghaij4BSeNRK5nRkJyAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Bars o matic",
          "title": "Bars o matic",
          "src": "/static/5aef2fa7e3636524ef3d89e061f7003c/a6d36/Bars-o-matic.png",
          "srcSet": ["/static/5aef2fa7e3636524ef3d89e061f7003c/222b7/Bars-o-matic.png 163w", "/static/5aef2fa7e3636524ef3d89e061f7003c/ff46a/Bars-o-matic.png 325w", "/static/5aef2fa7e3636524ef3d89e061f7003c/a6d36/Bars-o-matic.png 650w", "/static/5aef2fa7e3636524ef3d89e061f7003c/e548f/Bars-o-matic.png 975w", "/static/5aef2fa7e3636524ef3d89e061f7003c/ea64c/Bars-o-matic.png 1116w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span>{`
`}<a parentName="p" {...{
        "href": "https://observablehq.com/@kerryrodden/stacked-to-grouped-bars-o-matic"
      }}>{`https://observablehq.com/@kerryrodden/stacked-to-grouped-bars-o-matic`}</a></p>
    <h3>{`Pack-o-matic`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "49.69325153374233%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAAB1klEQVQoz1WSTY6bQBCFfSIfxGfwJnfx0rlFpESazDLLLKJIjuRdPMnIw48JPw0YDGQAYwxNv1T1GOQgtRroqq9fvaqZYVjLsqyXAJZt2y2bpn13Pl8WSqn39A+0y7dNYRgG3D/8LaXELU7vM4IhzwsUxSu67u2QoK9SDo+3POX7vsrzXCclSYLr9aoPPM+D67qUW0zQWZpm8uXFlE9Pv6VtO33fDyCFYujlAwfVda3CMGQoDocDhBDIsowu71BV1aR0UhhFR0WwcQ1UNtp+CKm4T3/rCp7rKYKoOI61OoKD4UEQ6HI3mw12u52G8TcBYxAIz897mIalLgQ8x2mSftt8ET9/4Y/nKcdxGAqGcpm2beN4PGK73WI+n2OxWMA0Tf2PFcKyDhTk4HTKte7KcE7Jh8evwfcfcMk/8onLpvPT5BurjKIIq9UK6/VavzdNg1kcJxoWhlzSSdV1g7JpQ+J+bMmXUISKgjWQy2SVI3z0jZvETdMeMpAVcsm+L5TuelZEQ9d/vhmuSJVuyqisLEsNY7WsjOFTl4NAYL839BIi0ldeLteIxubhFqRIgWLDOYHGQHeVV5qmuvOjunFsyFCLFhudUqLiOfwPyNv98N4/PIP3w/0PxxfoTCnH+b4AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Pack o matic",
          "title": "Pack o matic",
          "src": "/static/80335d65790391f8839d81d65a6ad041/a6d36/Pack-o-matic.png",
          "srcSet": ["/static/80335d65790391f8839d81d65a6ad041/222b7/Pack-o-matic.png 163w", "/static/80335d65790391f8839d81d65a6ad041/ff46a/Pack-o-matic.png 325w", "/static/80335d65790391f8839d81d65a6ad041/a6d36/Pack-o-matic.png 650w", "/static/80335d65790391f8839d81d65a6ad041/e548f/Pack-o-matic.png 975w", "/static/80335d65790391f8839d81d65a6ad041/95e27/Pack-o-matic.png 1199w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span>{`
`}<a parentName="p" {...{
        "href": "https://observablehq.com/@mbostock/pack-o-matic"
      }}>{`https://observablehq.com/@mbostock/pack-o-matic`}</a></p>
    <br />
    <p>{`Ou si on ne souhaite que ceux faits par Mike Bostock: `}<a parentName="p" {...{
        "href": "https://observablehq.com/collection/@mbostock/applications"
      }}>{`https://observablehq.com/collection/@mbostock/applications`}</a></p>
    <br />
    <p>{`Il existe aussi des `}<strong parentName="p">{`templates`}</strong>{`, cherchez bien.`}</p>
    <h1>{`Quelques détails ?`}</h1>
    <h2>{`Est ce performant ?`}</h2>
    <p>{`Observable ne contient pas de goulot d'étranglement de la performance. La vitesse est quasi la même qu'une application javascript `}<strong parentName="p">{`barebone`}</strong>{` avec quelques bénéfices supplémentaires:`}</p>
    <ul>
      <li parentName="ul">{`un chargement de 10 Mo n'est pas ré-executé à chaque modification en aval (un peu comme le hot reload react par exemple)`}</li>
      <li parentName="ul">{`un redimensionnement de fenêtre ne provoque que les stricts recalculs des paramètres qui changent et rien d'autre. C'est `}<strong parentName="li">{`automatique`}</strong></li>
    </ul>
    <br />
    <p>{`Mise au point sur la performance javascript et navigateur: J'entend beaucoup qui disent qu'il ne faut pas charger trop de données dans le navigateur (moins de 1 Mo) et que passé quelques centaines d'objets dans le navigateur les performances s'écroulent. `}</p>
    <br />
    <p><strong parentName="p">{`Faux`}</strong>{` `}</p>
    <br />
    <p>{`...à condition de ne pas être trop `}<strong parentName="p">{`bourrin`}</strong>{`. `}</p>
    <br />
    <p>{`Quelques exemples de notebook qui prouvent les performances de Observable/javascript exécutant des `}<strong parentName="p">{`algorithmes suffisamment intelligents`}</strong>{`. `}</p>
    <ul>
      <li parentName="ul">{`un `}<a parentName="li" {...{
          "href": "https://observablehq.com/d/0aef1dc9747b6120"
        }}>{`notebook`}</a>{` qui charge 1 million de trajets et les affiche au survol de chaque ville`}</li>
      <li parentName="ul">{`un `}<a parentName="li" {...{
          "href": "https://observablehq.com/@rreusser/2d-n-body-gravity-with-poissons-equation"
        }}>{`notebook`}</a>{` qui (avec l'aide de WebGL) simule l'interaction gravitationnelle de 250k corps `}</li>
      <li parentName="ul">{`un `}<a parentName="li" {...{
          "href": "https://observablehq.com/@alainro/can-quadtree-speed-up-zoom-of-many-points-on-a-canvas"
        }}>{`notebook`}</a>{` qui affiche 36k points (utilisation de canvas et optimisation qui permet de tracer uniquement ce qui est visible)`}</li>
    </ul>
    <br />
    <p>{`Observable n'est pas que pour les Proof Of Concept avec 200 lignes de données. Croyez moi, 100k de lignes de données ne présentent aucun problème. Et si la performane est insuffisante, il faut être malin.`}</p>
    <h2>{`En quoi est ce différent du pur javascript ?`}</h2>
    <p>{`Il y a quelques différences et des habitudes à prendre. Au tout début d'Observable, du temps où la documentation était très sommaire, je me rappele avoir passé des heures avant de comprendre qu'il fallait entourer de {} les définitions de cellules contenant plusieurs expressions. J'ai aussi longtemps cru que les ; étaient nécessaires. 😃`}</p>
    <p>{`Paradoxalement l'expert javascript risque d'être plus facilement perturbé que le néophyte des différences et apparentes restrictions: pas de variables globales, pas de références circulaires, ... `}</p>
    <br />
    <p>{`Courage ! Vous verrez qu'il est facile de faire autrement que d'habitude et que le résultat est bien plus robuste.`}</p>
    <br />
    <p>{`Un notebook qui explique les principaux `}<a parentName="p" {...{
        "href": "https://observablehq.com/@observablehq/observables-not-javascript"
      }}>{`détails`}</a>{` techniques.`}</p>
    <h1>{`Ce qu'il faut en penser ?`}</h1>
    <p><strong parentName="p">{`Mon avis est qu'Observable est un très bon choix dans beaucoup de situations. Il permet d'éviter le déploiement d'un environnement de développement javascript 'professionnel': github, editeurs spécialisés, webpack, react, npm, ... : zéro installation`}</strong></p>
    <br />
    <p><strong parentName="p">{`S'il faut mettre un néophyte dans la boucle pour donner son avis sur une visualisation alors il est bien plus adapté que n'importe quoi d'autre. D'expérience, il est très facile d'itérer avec un client ou de collaborer avec un partenaire sur des algorithmes et des jeux de données de taille 'professionnelle'.`}</strong></p>
    <br />
    <p><strong parentName="p">{`Si j'ai réussi à vous donner envie d'essayer Observable dans votre équipe et votre contexte, sachez que je peux vous aider à mettre en place les bonnes habitudes pour vous sentir très rapidement à l'aise que vous soyez débutant ou confirmé javascript.`}</strong></p>
    <br />
    <p><strong parentName="p">{`Et depuis l'écriture initiale de ce billet, Observable a rajouté beaucoup de fonctionnalités. Je vais en citer deux :`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`le petit navigateur à droite qui permet de voir "qui dépend de quoi"`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`la collaboration en temps réel avec les curseurs de chacun des collaborateurs qui bougent`}</strong></li>
    </ul>
    <br />
    <p>{`N'hésitez pas à nous contacter.`}</p>
    <br />
    <p>{`La vidéo du meetup Observable: Sur `}<a parentName="p" {...{
        "href": "https://youtu.be/muijTV1tzcY"
      }}>{`youtube`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      